import React, { useState } from "react"
import styled from "styled-components"
import is from "styled-is"
// Animation
import { useSpring, animated, config } from "react-spring"
import useMeasure from "react-use-measure"
import { ResizeObserver } from "@juggle/resize-observer"

// Components
import { TextBlock, StandardContainer } from "../../common"

// Styles
const StyledAccordionItem = styled.div`
  margin: 1rem 0;
  > button {
    padding: 1rem 0;
    border-bottom: 1px solid ${props => props.theme.colors.textColor};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &:after {
      content: "+";
      font-size: 21px;
      color: ${props => props.theme.colors.pullColor};
    }
  }
  ${TextBlock} {
    padding: 2rem 0;
  }
  ${is("open")`
     > button:after{
       content: "-";
     }
  `}
`
const StyledAccordions = styled(StandardContainer)`
  margin: 4rem auto;
`

const AcordionItem = ({ heading, text }) => {
  const [open, setOpen] = useState(false)
  const [ref, measure] = useMeasure({ polyfill: ResizeObserver })

  const { x, y } = useSpring({
    x: open ? measure.height : 0,
    y: open ? 1 : 0,
    config: config.default,
  })
  return (
    <StyledAccordionItem open={open}>
      <button onClick={() => setOpen(!open)}>
        <h3>{heading.text}</h3>
      </button>
      <animated.div
        style={{
          height: x,
          opacity: y.interpolate(y => y),
          overflow: "hidden",
        }}
      >
        <TextBlock dangerouslySetInnerHTML={{ __html: text.html }} ref={ref} />
      </animated.div>
    </StyledAccordionItem>
  )
}

export const Accordions = ({ sections }) => {
  return (
    <StyledAccordions as="section" wrappedNarrow>
      {sections.map(item => (
        <AcordionItem {...item} />
      ))}
    </StyledAccordions>
  )
}
