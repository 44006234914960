import React from "react"
import styled from "styled-components"

// Components
import { TextBlock, StandardContainer } from "../../common"
// Styles

const StyledTwoColumnText = styled(StandardContainer)`
  > h2 {
    margin-bottom: 2rem;
  }
  @media ${props => props.theme.breakpoints.medium} {
    > ${TextBlock} {
      column-count: 2;
      column-gap: 3rem;
      p {
        padding-top: 0;
      }
    }
  }
`

export const TwoColumnText = ({ heading, text }) => {
  return (
    <StyledTwoColumnText>
      {heading.text && <h2>{heading.text}</h2>}
      <TextBlock dangerouslySetInnerHTML={{ __html: text.html }} />
    </StyledTwoColumnText>
  )
}
