import React from "react"

// Components
import { TextBlock, StandardContainer } from "../../common"

export const StandardTextBlock = ({ text }) => {
  return (
    <StandardContainer>
      <TextBlock dangerouslySetInnerHTML={{ __html: text.html }} />
    </StandardContainer>
  )
}
