import React from "react"
import styled from "styled-components"

// Components
import {
  TextBlock,
  StyledButtonLink,
  FlexContainer,
  PrismicImage,
} from "../../common"

// Styles
const StyledSecondaryBanner = styled.section`
  background: ${props => props.bgColor};
  padding-top: 3rem;
  .gatsby-image-wrapper,
  img {
    width: 100%;
  }
  ${TextBlock} {
    margin-bottom: 3rem;
    > ${StyledButtonLink} {
      margin-top: 1rem;
      display: inline-block;
    }
  }
  @media ${props => props.theme.breakpoints.medium} {
    .gatsby-image-wrapper,
    img {
      width: 48%;
      align-self: flex-end;
    }
    ${TextBlock} {
      width: 48%;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    ${TextBlock} {
      width: 42%;
    }
  }
`

export const SecondaryBanner = ({
  image,
  text,
  link_text,
  page_link,
  background_colour,
}) => {
  return (
    <StyledSecondaryBanner bgColor={background_colour}>
      <FlexContainer
        wrappedStandard
        wrap
        alignCenter
        justifyBetween
        rowReverse-m
      >
        <TextBlock>
          <div dangerouslySetInnerHTML={{ __html: text.html }} />
          {page_link.url && (
            <StyledButtonLink to={page_link.url}>{link_text}</StyledButtonLink>
          )}
        </TextBlock>
        <PrismicImage {...image} />
      </FlexContainer>
    </StyledSecondaryBanner>
  )
}
