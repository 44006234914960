import React from "react"
import styled, { css } from "styled-components"
import { match } from "styled-is"

const StyledPageSpacer = styled.div`
  display: block;
  width: 100%;
  padding-left: ${props => props.theme.padding.hMobile};
  padding-right: ${props => props.theme.padding.hMobile};
  max-width: ${props => props.theme.containerWidths.standard};
  margin: 0 auto;
  padding-top: ${props =>
    props.size === "Standard"
      ? props.theme.pageSpacers.small
      : props.size === "Medium"
      ? props.theme.pageSpacers.medium
      : props.theme.pageSpacers.large};
  padding-bottom: ${props =>
    props.size === "Standard"
      ? props.theme.pageSpacers.small
      : props.size === "Medium"
      ? props.theme.pageSpacers.medium
      : props.theme.pageSpacers.large};

  ${props =>
    props.border &&
    css`
      &:before {
        content: "";
        width: 100%;
        height: 3px;
        display: block;
        background: ${props => props.theme.colors.borderStandard};
      }
    `}

  ${match("navSpacer", "true")`
    margin-top: 115px;
  `}  

  @media ${props => props.theme.breakpoints.medium} {
    padding-left: ${props => props.theme.padding.hDesk};
    padding-right: ${props => props.theme.padding.hDesk};
    ${match("navSpacer", "true")`
      margin-top: 70px;
    `}  
  }
`

export const PageSpacer = ({ size, border, nav_spacer }) => {
  const isBorder = border === "true"
  return (
    <StyledPageSpacer size={size} border={isBorder} navSpacer={nav_spacer} />
  )
}
