import React from "react"
import styled from "styled-components"
// Components
import { StandardContainer, StyledButtonLink } from "../../common"

// Styles
const StyledCenteredHeading = styled(StandardContainer)`
  text-align: center;
  > h2 {
    max-width: ${props => props.theme.measures.standard};
    margin: 0 auto 2rem auto;
  }
`

export const CenteredHeading = ({
  heading,
  subheading,
  page_link,
  link_text,
}) => {
  return (
    <StyledCenteredHeading as="section">
      {subheading.text && <h5>{subheading.text}</h5>}
      <h2>{heading.text}</h2>
      {page_link.url && link_text && (
        <StyledButtonLink to={page_link.url}>{link_text}</StyledButtonLink>
      )}
    </StyledCenteredHeading>
  )
}
