import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import is from "styled-is"
// SVG
import ArrowRight from "../../svg/arrow-right.svg"
// Components
import { TextBlock, PrismicImage, StyledLinkAlt } from "../common"
// Styles
const CardImage = styled(Link)`
  width: 100%;
  align-self: flex-start;
`
const StyledCarouselCard = styled.div`
  background: ${props => props.theme.colors.darkBg};
  padding: 1rem 2rem;
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.3rem;
  > ${TextBlock} {
    color: #fff;
    align-self: flex-end;
    padding: 1rem 0;
  }
  ${is("carousel")`
    > ${CardImage}{
      padding: 0 2rem;
    }
  `}
`

export const CaseStudyCard = ({ data, url, ...props }) => {
  const { title, excerpt, portal_image } = data
  return (
    <StyledCarouselCard {...props}>
      <CardImage to={url}>
        <PrismicImage {...portal_image} />
      </CardImage>
      <TextBlock>
        <Link to={url}>
          <h3>{title.text}</h3>
          <p>{excerpt.text}</p>
        </Link>

        <StyledLinkAlt to={url} reversed>
          Read More <ArrowRight />
        </StyledLinkAlt>
      </TextBlock>
    </StyledCarouselCard>
  )
}
