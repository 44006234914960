import React from "react"
import styled from "styled-components"
import { match } from "styled-is"
// Components
import Flex from "styled-flex-component"
import {
  TextBlock,
  StandardContainer,
  StyledButtonLink,
  PrismicImage,
} from "../../common"

// Styles
const StyledTextAndFeatures = styled(StandardContainer)`
  @media ${props => props.theme.breakpoints.medium} {
    ${match("layout", "Inline")`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 5rem;
    `};
  }
`

const StyledFeatureItem = styled(Flex)`
  > .gatsby-image-wrapper,
  img {
    width: 60px;
    align-self: flex-start;
  }
  > ${TextBlock} {
    width: calc(100% - 70px);
    h3 {
      margin: 0;
    }
    p {
      padding: 0;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    > .gatsby-image-wrapper,
    img {
      width: 80px;
    }
    > ${TextBlock} {
      width: calc(100% - 90px);
    }
  }
`

const StyledTextFeatureList = styled.div`
  display: grid;
  margin: 2rem 0;
  grid-row-gap: 2rem;
  @media ${props => props.theme.breakpoints.medium} {
    ${match("layout", "Inline")`
      margin: 0;
    `}
    ${match("layout", "Stacked")`
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
    `};
  }
  @media ${props => props.theme.breakpoints.large} {
    ${match("layout", "Stacked")`
      grid-gap: 5rem;
    `};
  }
`

const FeatureItem = ({ icon, heading, text }) => {
  return (
    <StyledFeatureItem wrap justifyBetween>
      <PrismicImage {...icon} />
      <TextBlock>
        <h3>{heading.text}</h3>
        <div dangerouslySetInnerHTML={{ __html: text.html }} />
      </TextBlock>
    </StyledFeatureItem>
  )
}

export const TextAndFeatures = ({
  layout,
  text,
  link_text,
  page_link,
  features,
}) => {
  return (
    <StyledTextAndFeatures layout={layout} wrappedNarrow>
      <TextBlock>
        <div dangerouslySetInnerHTML={{ __html: text.html }} />
        {page_link.url && link_text && (
          <StyledButtonLink to={page_link.url}>{link_text}</StyledButtonLink>
        )}
      </TextBlock>
      <StyledTextFeatureList layout={layout}>
        {features.map(item => (
          <FeatureItem {...item} />
        ))}
      </StyledTextFeatureList>
    </StyledTextAndFeatures>
  )
}
