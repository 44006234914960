import React, { useState, useEffect } from "react"
import styled from "styled-components"

// SVG
import ArrowRight from "../../svg/arrow-right.svg"
import ArrowLeft from "../../svg/arrow-left.svg"
// Components
import EmblaCarouselReact from "embla-carousel-react"
import Flex from "styled-flex-component"
//Styles
const StyledCarouselCanvas = styled.div`
  display: flex;
  align-items: center;
  cursor: grab;
  flex-wrap: nowrap;
`

const StyledCardCarouselWrapper = styled.div`
  padding: 0 ${props => props.theme.padding.hMobile};
  max-width: ${props => props.theme.containerWidths.standard};
  margin: auto;
  overflow: visible;
  @media ${props => props.theme.breakpoints.medium} {
    padding: 0 ${props => props.theme.padding.hDesk};
  }
`

const StyledCardCarousel = styled(EmblaCarouselReact)`
  overflow: visible !important;
`

const StyledCarouselHeader = styled(Flex)`
  padding: 2.5rem 0;
  svg {
    width: 40px;
  }
`

export const CardCarousel = ({ children, heading }) => {
  const [embla, setEmbla] = useState(null)
  const [prev, setPrev] = useState(false)
  useEffect(() => {
    if (embla) {
      embla.on("select", () => {
        if (embla.canScrollPrev()) {
          setPrev(true)
        } else {
          setPrev(false)
        }
      })
    }
  }, [embla])
  return (
    <StyledCardCarouselWrapper>
      <StyledCarouselHeader justifyBetween alignCenter>
        <h2>{heading.text}</h2>
        <Flex>
          {prev && (
            <button onClick={() => embla.scrollPrev()}>
              <ArrowLeft />
            </button>
          )}
          <button onClick={() => embla.scrollNext()}>
            <ArrowRight />
          </button>
        </Flex>
      </StyledCarouselHeader>
      <StyledCardCarousel
        emblaRef={setEmbla}
        options={{
          loop: false,
          startIndex: 0,
          containScroll: true,
          dragFree: true,
        }}
      >
        <StyledCarouselCanvas>{children}</StyledCarouselCanvas>
      </StyledCardCarousel>
    </StyledCardCarouselWrapper>
  )
}
