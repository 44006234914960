import React from "react"
import styled from "styled-components"

// Components
import { CardCarousel } from "../../carousels"
import { CaseStudyCard } from "../../cards"

const CarouselCardWrapper = styled.div`
  flex: 0 0 auto;
  width: 100%;
  user-select: none;
  padding: 0 ${props => props.theme.padding.hMobile};
  box-sizing: content-box;
  position: relative;
  display: flex;
  align-self: stretch;
  &:before {
    content: "";
    width: calc(100% - (${props => props.theme.padding.hMobile} * 2));
    height: 100%;
    background: ${props => props.theme.colors.darkBg};
    position: absolute;
    top: 0;
    left: ${props => props.theme.padding.hMobile};
    border-radius: 0.3rem;
  }

  @media ${props => props.theme.breakpoints.medium} {
    width: 400px;
    user-select: none;
    padding: 0 calc(${props => props.theme.padding.hMobile} * 2) 0 0;
    &:before {
      left: 0;
    }
  }
`

export const UseCaseCarousel = ({ heading, items }) => {
  return (
    <CardCarousel heading={heading}>
      {items.map(item => (
        <CarouselCardWrapper>
          <CaseStudyCard {...item.case_reference.document} carousel />
        </CarouselCardWrapper>
      ))}
    </CardCarousel>
  )
}
