import React from "react"
import styled from "styled-components"

// Compoents
import {
  StandardContainer,
  FlexContainer,
  TextBlock,
  PrismicImage,
} from "../../common"

const StyledFeatureGrid = styled(StandardContainer)`
  display: grid;
  grid-row-gap: 2rem;
  margin: 4rem auto;
  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4rem;
    margin: 6rem auto;
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5rem;
  }
`
const FeatureGridItem = styled(FlexContainer)`
  > .gatsby-image-wrapper,
  img {
    width: 60px;
    align-self: flex-start;
  }
  > ${TextBlock} {
    width: calc(100% - 70px);
    h3 {
      margin: 0;
    }
    p {
      padding: 0;
    }
  }
  @media ${props => props.theme.breakpoints.medium} {
    text-align: center;
    > .gatsby-image-wrapper,
    img {
      width: 80px;
      align-self: flex-start;
    }
    > ${TextBlock} {
      width: 100%;
    }
  }
`

export const FeatureGrid = ({ features }) => {
  return (
    <StyledFeatureGrid>
      {features.map(item => {
        const { icon, heading, text } = item
        return (
          <FeatureGridItem wrap justifyBetween justifyCenter-m>
            <PrismicImage {...icon} />
            <TextBlock>
              <h3>{heading.text}</h3>
              <div dangerouslySetInnerHTML={{ __html: text.html }} />
            </TextBlock>
          </FeatureGridItem>
        )
      })}
    </StyledFeatureGrid>
  )
}
