import React from "react"
import styled from "styled-components"

// Components
import { CaseStudyCard } from "../../cards"
import { StandardContainer } from "../../common"

const StyledUseCaseGrid = styled(StandardContainer)`
  display: grid;
  grid-gap: 1.5rem;

  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const UseCaseGrid = ({ items }) => {
  return (
    <StyledUseCaseGrid>
      {items.map(item => (
        <CaseStudyCard {...item.case_reference.document} />
      ))}
    </StyledUseCaseGrid>
  )
}
