import React from "react"
import PropTypes from "prop-types"

// Import Slices
import {
  PageSpacer,
  PageBanner,
  SecondaryBanner,
  StandardTextBlock,
  TwoColumnText,
  CenteredHeading,
  TextAndFeatures,
  FeatureGrid,
  ImageAndText,
  StaffBios,
  Accordions,
  UseCaseCarousel,
  UseCaseGrid,
  MagnetForm,
} from "./"

export const SliceZone = ({ slices }) => {
  const slice = slices.map(s => {
    switch (s.slice_type) {
      case "page_spacer":
        return <PageSpacer {...s.primary} key={s.id} />
      case "page_banner":
        return <PageBanner {...s.primary} key={s.id} />
      case "secondary_banner":
        return <SecondaryBanner {...s.primary} key={s.id} />
      case "standard_text_block":
        return <StandardTextBlock {...s.primary} key={s.id} />
      case "two_column_text":
        return <TwoColumnText {...s.primary} key={s.id} />
      case "centered_heading":
        return <CenteredHeading {...s.primary} key={s.id} />
      case "text___features":
        return <TextAndFeatures {...s.primary} features={s.items} key={s.id} />
      case "feature_grid":
        return <FeatureGrid features={s.items} key={s.id} />
      case "image___text":
        return <ImageAndText {...s.primary} key={s.id} />
      case "staff_bios":
        return <StaffBios {...s.primary} bios={s.items} key={s.id} />
      case "accordions":
        return <Accordions {...s.primary} sections={s.items} key={s.id} />
      case "use_case_carousel":
        return <UseCaseCarousel {...s.primary} items={s.items} key={s.id} />
      case "use_case_grid":
        return <UseCaseGrid items={s.items} key={s.id} />
      case "magnet_form":
        return <MagnetForm {...s.primary} key={s.id} />
      default:
        return null
    }
  })
  return <>{slice}</>
}

SliceZone.propTypes = {
  slices: PropTypes.array.isRequired,
}
