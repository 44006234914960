import React from "react"
import styled from "styled-components"
import { match } from "styled-is"

// Components
import {
  PrismicImage,
  TextBlock,
  StyledButtonLink,
  FlexContainer,
} from "../../common"

// Styles
const StyledImageText = styled(FlexContainer)`
  .gatsby-image-wrapper,
  img {
    width: 100%;
  }
  > ${TextBlock} {
    margin-top: 3rem;
  }
  @media ${props => props.theme.breakpoints.medium} {
    .gatsby-image-wrapper,
    img {
      width: calc(50% - 1rem);
      align-self: center;
    }
    > ${TextBlock} {
      margin-top: 0;
      width: calc(50% - 1rem);
    }
    ${match("reverse", "true")`
      flex-direction: row-reverse;
    `}
  }
  @media ${props => props.theme.breakpoints.large} {
    > ${TextBlock} {
      width: 45%;
    }
    .gatsby-image-wrapper,
    img {
      width: 48%;
      align-self: center;
    }
  }
`

export const ImageAndText = ({
  image,
  text,
  page_link,
  link_text,
  reverse_layout,
}) => {
  return (
    <StyledImageText
      wrappedStandard
      wrap
      justifyBetween-m
      alignCenter-m
      reverse={reverse_layout}
    >
      <PrismicImage {...image} />
      <TextBlock>
        <div dangerouslySetInnerHTML={{ __html: text.html }} />
        {page_link.url && link_text && (
          <StyledButtonLink to={page_link.url}>{link_text}</StyledButtonLink>
        )}
      </TextBlock>
    </StyledImageText>
  )
}
