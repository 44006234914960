import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

// Hooks
import useFormValidation from "../../forms/useFormValidation"

// Components
import {
  StandardContainer,
  TextBlock,
  StyledButton,
  StyledTransparentInput,
} from "../../common"

// Styles

const StyledMagnetForm = styled.div`
  background: ${props => props.theme.colors.darkBg};
  padding: 8rem 0;
  min-height: 100vh;
  position: relative;
  > ${StandardContainer} {
    z-index: 1;
    position: relative;
    width: 100%;
    color: #fff;
    display: grid;

    grid-row-gap: 2rem;
  }
  form {
    align-self: center;
    input {
      width: 100%;
      margin-bottom: 1rem;
    }
    button {
      width: 100%;
    }
    > ${TextBlock} {
      text-align: center;
    }
  }
  @media ${props => props.theme.breakpoints.medium} {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media ${props => props.theme.breakpoints.large} {
    > ${StandardContainer} {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 4rem;
      grid-row-gap: 0;
    }
  }
`

const StyledBlockBackground = styled(BackgroundImage)`
  position: absolute !important;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  left: 0;
`

const INITIAL_STATE = {}

export const MagnetForm = ({
  background_image,
  download,
  text,
  thank_you_text,
  email_heading,
  email_text,
  email_button_text,
  email_banner,
}) => {
  const { handleSubmit, handleChange, success } = useFormValidation({
    ...INITIAL_STATE,
    emailHeading: email_heading.text,
    emailText: email_text.text,
    buttonText: email_button_text,
    fileDownload: download.url,
    emailBanner: email_banner.url,
  })
  return (
    <StyledMagnetForm>
      {background_image && (
        <StyledBlockBackground fluid={background_image.fluid} />
      )}
      <StandardContainer>
        <TextBlock dangerouslySetInnerHTML={{ __html: text.html }} />
        <form
          name="magnet-form"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="magnet-form" />

          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </p>
          {success ? (
            <TextBlock
              dangerouslySetInnerHTML={{ __html: thank_you_text.html }}
            />
          ) : (
            <>
              <StyledTransparentInput
                type="text"
                name="fullName"
                placeholder="Full Name*"
                onChange={handleChange}
                required
              />
              <StyledTransparentInput
                type="email"
                name="email"
                placeholder="Email Address*"
                onChange={handleChange}
                required
              />
              <StyledTransparentInput
                type="text"
                name="companyName"
                placeholder="Company Name*"
                onChange={handleChange}
                required
              />
              <input type="hidden" name="emailHeading" />
              <input type="hidden" name="emailText" />
              <input type="hidden" name="buttonText" />
              <input type="hidden" name="fileDownload" />
              <input type="hidden" name="emailBanner" />
              <StyledButton type="submit">Submit</StyledButton>
            </>
          )}
        </form>
      </StandardContainer>
    </StyledMagnetForm>
  )
}
