import React from "react"
import styled from "styled-components"
import Scroll from "react-scroll"
import useMeasure from "react-use-measure"
import { ResizeObserver } from "@juggle/resize-observer"

// Components
import { Spring } from "react-spring/renderprops"
import Flex from "styled-flex-component"
import {
  TextBlock,
  StyledButton,
  PrismicImage,
  StyledButtonLink,
} from "../../common"

// Animation
const AnimationConfig = {
  tension: 280,
  friction: 120,
  clamp: true,
}
const ImageAnimationConfig = {
  tension: 280,
  friction: 120,
  clamp: true,
}
const AnimationDelay = 250

// Styles
const StyledPageBanner = styled.div`
  position: relative;
  @media ${props => props.theme.breakpoints.large} {
    background: ${props => props.theme.colors.darkBg};
    &:before {
      content: "";
      width: 100%;
      height: 5rem;
      background: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
`
const BannerInner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5rem auto 2rem auto;
  grid-row-gap: 2rem;
  z-index: 2;
  position: relative;

  &:before {
    content: "";
    background: ${props => props.theme.colors.darkBg};
    grid-row: 1 / span 2;
    grid-column: 1;
  }

  @media ${props => props.theme.breakpoints.medium} {
    grid-template-rows: 5rem auto 5rem auto;
  }

  @media ${props => props.theme.breakpoints.large} {
    padding-left: ${props => props.theme.padding.hDesk};
    padding-right: ${props => props.theme.padding.hDesk};
    max-width: ${props => props.theme.containerWidths.standard};
    margin: 0 auto;
    grid-template-columns: 1fr 1fr;
    grid-template-rows:
      calc(
        ${props => props.theme.pageSpacers.large} + 160 * (100vw - 320px) / 2180
      )
      auto 5rem;
    grid-column-gap: 1rem;
    grid-row-gap: 0;
    &:before {
      display: none;
    }
  }
`

const PageBannerText = styled(TextBlock)`
  grid-row: 4;
  align-self: center;
  padding-left: ${props => props.theme.padding.hMobile};
  padding-right: ${props => props.theme.padding.hMobile};
  padding-bottom: 3rem;
  button,
  a {
    margin-right: 0.8rem;
    color: #fff;
  }
  > ${Flex} {
    margin-top: 1rem;
  }

  @media ${props => props.theme.breakpoints.medium} {
    padding-left: ${props => props.theme.padding.hDesk};
    padding-right: ${props => props.theme.padding.hDesk};
    > ${Flex} {
      flex-direction: row;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-column: 1;
    grid-row: 2;
    align-self: center;
    color: #ffffff;
    padding-left: 0;
    padding-right: 0;
    .banner-text {
      /* > p {
        opacity: 0.5;
      } */
    }
  }
`

const PageBannerImage = styled.div`
  grid-column: 1;
  grid-row: 2 / span 2;
  padding-left: ${props => props.theme.padding.hMobile};
  padding-right: ${props => props.theme.padding.hMobile};

  @media ${props => props.theme.breakpoints.medium} {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-column: 2;
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-row: 2 / span 2;
    padding-left: 0;
    padding-right: 0;
  }
`

export const PageBanner = ({
  image,
  heading,
  subheading,
  text,
  scroll_enticer_text,
  page_link,
  link_text,
}) => {
  const scroll = Scroll.animateScroll
  const [ref, measure] = useMeasure({ polyfill: ResizeObserver })

  return (
    <StyledPageBanner ref={ref}>
      <BannerInner>
        <PageBannerText>
          {subheading.text && (
            <Spring
              from={{ opacity: 0, transform: "translate3d(0,20px,0)" }}
              to={{ opacity: 1, transform: "translate3d(0,0,0)" }}
              delay={AnimationDelay}
              config={AnimationConfig}
            >
              {props => <h4 style={props}>{subheading.text}</h4>}
            </Spring>
          )}
          <Spring
            from={{ opacity: 0, transform: "translate3d(0,20px,0)" }}
            to={{ opacity: 1, transform: "translate3d(0,0,0)" }}
            delay={!subheading.text ? AnimationDelay : AnimationDelay * 2}
            config={AnimationConfig}
          >
            {props => <h1 style={props}>{heading.text}</h1>}
          </Spring>
          <Spring
            from={{ opacity: 0, transform: "translate3d(0,20px,0)" }}
            to={{ opacity: 1, transform: "translate3d(0,0,0)" }}
            delay={!subheading.text ? AnimationDelay * 2 : AnimationDelay * 3}
            config={AnimationConfig}
          >
            {props => (
              <div
                dangerouslySetInnerHTML={{ __html: text.html }}
                style={props}
                className="banner-text"
              />
            )}
          </Spring>

          <Spring
            from={{ opacity: 0, transform: "translate3d(0,20px,0)" }}
            to={{ opacity: 1, transform: "translate3d(0,0,0)" }}
            delay={!subheading.text ? AnimationDelay * 3 : AnimationDelay * 4}
            config={AnimationConfig}
          >
            {props => (
              <Flex column style={props}>
                {scroll_enticer_text && (
                  <StyledButton onClick={() => scroll.scrollTo(measure.height)}>
                    {scroll_enticer_text}
                  </StyledButton>
                )}
                {page_link.url && link_text && (
                  <StyledButtonLink to={page_link.url}>
                    {link_text}
                  </StyledButtonLink>
                )}
              </Flex>
            )}
          </Spring>
        </PageBannerText>
        <Spring
          from={{ opacity: 0, transform: "translate3d(-20px,10px,0px)" }}
          to={{ opacity: 1, transform: "translate3d(0px,0px,0px)" }}
          delay={AnimationDelay}
          config={ImageAnimationConfig}
        >
          {props => (
            <PageBannerImage style={props}>
              <PrismicImage {...image} />
            </PageBannerImage>
          )}
        </Spring>
      </BannerInner>
    </StyledPageBanner>
  )
}
