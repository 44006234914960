import React, { useState } from "react"
import styled from "styled-components"

// Components
import {
  TextBlock,
  PrismicImage,
  StandardContainer,
  StyledButtonAlt,
  StandardModal,
} from "../../common"
import ArrowRight from "../../../svg/arrow-right.svg"

// Styles
const StaffBioGrid = styled.div`
  margin: 2rem 0;
  display: grid;
  grid-row-gap: 4rem;
  > div {
    cursor: pointer;
    ${TextBlock} {
      padding: 1rem 0;
      p {
        padding: 0;
      }
    }
  }
  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-column-gap: 5rem;
    margin: 3rem 0;
  }
`

const BioCard = ({ image, name, excerpt, ...props }) => {
  return (
    <div {...props}>
      <PrismicImage {...image} />
      <TextBlock>
        <h3>{name.text}</h3>
        <p>{excerpt.text}</p>
      </TextBlock>
      <StyledButtonAlt>
        Read More <ArrowRight />
      </StyledButtonAlt>
    </div>
  )
}

export const StaffBios = ({ bios, heading }) => {
  const [bio, setBio] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <>
      <StandardContainer as="section">
        {heading.text && <h2>{heading.text}</h2>}
        <StaffBioGrid>
          {bios.map(item => {
            return (
              <BioCard
                {...item}
                onClick={() => {
                  setBio(item)
                  setModalOpen(true)
                }}
              />
            )
          })}
        </StaffBioGrid>
      </StandardContainer>
      <StandardModal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false)
          setTimeout(() => {
            setBio(null)
          }, 1000)
        }}
      >
        {bio && (
          <TextBlock>
            <h2>{bio.name.text}</h2>
            <div dangerouslySetInnerHTML={{ __html: bio.full_bio.html }} />
          </TextBlock>
        )}
      </StandardModal>
    </>
  )
}
